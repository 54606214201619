@use "../config/" as *;
.waf-wp-shop-checkout {
    @extend %pb-8;
    @extend %pure-white-900-bg;
    @extend %px-4;
    .form {
        &-head {
            @extend %mb-7;
            @extend %flex-sb-c;
            .edit {
                &-text {
                    @extend %font-12-pm;
                    @extend %neutral-800;
                    @extend %uppercase;
                    &::before {
                        padding-right: var(--space-1);
                        @include user-setting(edit-profile, 14);
                    }
                }
            }
        }
        &-title {
            @extend %font-20-pb;
            @extend %capitalize;
        }
        &-group {
            &.error {
                .form-input {
                    border-color: var(--error-800);
                }
                .form-label {
                    @extend %error-800;
                }
            }
            &:not(:last-child) {
                margin-bottom: var(--space-4);
            }
            .form-input-drop {
                &::after {
                    font-size: 2rem;
                }
            }
            &-save-card {
                .radio-box::after {
                    display: none;
                }
            }
        }
        &-wrapper {
            &:not(:last-child) {
                @extend %mb-10;
            }
        }
        &-input {
            text-transform: none;
        }
    }
    .accordion {
        &-header {
            @include border(1, neutral-1000);
            @extend %flex-sb-c;
            @extend %p-4;
            @extend %w-100;
            @extend %half-radius;
            &::after {
                @include icon(p-arrow-right);
                // transform: rotate(180deg);
                @extend %neutral-500;
            }
            &[aria-expanded="true"] {
                border-radius: var(--half-radius) var(--half-radius) 0 0;
                border-bottom: none;
                &::after {
                    transition: .3s all ease;
                    transform: rotate(90deg);
                    @extend %neutral-500;
                }
                ~ .accordion-body {
                    grid-template-rows: 1fr;
                    .custom-form {
                        @include border(1, neutral-1000);
                        border-top: none;
                    }
                }
            }
            .btn-text {
                @extend %font-14-pr;
            }
        }
        &-body {
            display: grid;
            grid-template-rows: 0fr;
            transition: grid-template-rows 0.5s ease-out;
            .custom-form {
                overflow: hidden;
            }
            .radio-box {
                @extend %py-5;
                @extend %w-100;
            }
            .StripeElement {
                border: 0.1rem solid hsl(var(--hsl-neutral-1000)/1);
                @extend %half-radius;
                @extend %p-4;
                @extend %my-3;
            }
        }
    }
    .card {
        &-image {
            width: 3.4rem;
        }
        &-number {
            .card-image {
                @include position-combo(y-center);
                left: 1.6rem;
                translate: none;
            }
            .form-input {
                @extend %pl-14;
            }
        }
    }
    .form {
        &-body {
            .custom-form {
                border-radius: 0 0 var(--half-radius) var(--half-radius);
            }
        }
        &-footer {
            .btn {
                &-group {
                    @extend %my-4;
                }
                &-place-order {
                    @extend %flex-c-c;
                    @extend %p-4;
                    @extend %primary-300-bg;
                    @extend %half-radius;
                    @extend %w-100;
                }
                &-text {
                    @extend %font-14-pb;
                    @extend %uppercase;
                }
            }
        }
    }
    .radio {
        &-container {
            @extend %relative;
            @extend %gap-2;
            @extend %flex-n-n;
            cursor: pointer;
            user-select: none;
            @extend %relative;
            @extend %pl-7;
            @extend %capitalize;
            @extend %font-14-pm;
            @extend %neutral-800;
            input {
                opacity: 0;
                left: 0;
                cursor: pointer;
                @extend %d-none;
                @extend %pos-y-center;
                &:checked {
                    ~ .checkmark {
                        border: 0.1rem solid var(--primary-300);
                        &:after {
                            content: '';
                        }
                    }
                }
            }
            .checkmark {
                height: 1.8rem;
                aspect-ratio: 1/1;
                border: 0.1rem solid var(--neutral-800);
                left: 0;
                @extend %circle-radius;
                @extend %pos-y-center;
                &:after {
                    width: 1rem;
                    aspect-ratio: 1/1;
                    @extend %circle-radius;
                    @extend %primary-300-bg;
                    @extend %pos-center;
                }
            }
        }
        &-box {
            @extend %px-4;
            @extend %relative;
            .number-value {
                @extend %font-14-pm;
                @extend %mt-1-neg;
            }
            .validity {
                @include position(null, null, 0, 7rem, absolute);
                @extend %font-10-pr;
                @extend %neutral-500;
            }
            &::after {
                content: '';
                height: .1rem;
                width: calc(100% - var(--space-8));
                @include position(null, 0, 0, 0, absolute);
                @extend %neutral-1000-bg;
                @extend %mx-auto;
            }
        }
        &-container {
            @extend %font-14-pb;
            @extend %neutral-800;
        }
    }
    .new-card-section {
        flex-wrap: wrap;
        gap: 0 var(--space-4);
        @extend %pb-4;
        @extend %px-4;
        @extend %flex;
        .radio-box {
            @extend %px-0;
            &::after {
                @extend %d-none;
            }
        }
    }
    .payment {
        &-link {
            @extend %flex-c-c;
            @extend %p-4;
            @extend %gap-2;
            @extend %neutral-900-bg;
            @extend %half-radius;
            &:not(:last-child) {
                @extend %mb-4;
            }
            &:nth-child(3) {
                background-color: #00D66F;
                .payment-text {
                    font-size: 1.8rem;
                    @extend %neutral-800;
                }
            }
        }
        &-text {
            @extend %font-20-pm;
            @extend %pure-white-900;
        }
        &-logo {
            max-width: 6rem;
        }
    }
    .globalmsg {
        @extend %py-4;
        @extend %text-center;
        span {
            @extend %d-block;
            @extend %font-16-pb;
        }
        .errordiv {
            @extend %error-800;
        }
        .successdiv {
            @extend %success-800;
        }
    }
    .price.meta-item {
        @extend %d-none;
    }
    .cart-section {
        @include border(1, neutral-1000);
        @extend %px-4;
        @extend %half-radius;
    }
    .status-text {
        @extend %d-none;
    }
    .product-wrap {
        gap: var(--space-2);
    }
    .shopping-cart-wrapper {
        .summary-title {
            border-bottom: none;
            @extend %mb-0;
        }
        .summary-list {
            @extend %pt-6;
        }
        .order-heading {
            border-bottom: .1rem solid var(--neutral-1000);
            @extend %flex-sb-c;
        }
        .edit {
            &-text {
                @extend %font-12-pm;
                @extend %neutral-800;
                @extend %uppercase;
                &::before {
                    padding-right: var(--space-1);
                    @include user-setting(edit-profile, 14);
                }
            }
        }
        .article {
            &-item {
                @include border(1, neutral-1000, 10, bottom);
                @extend %relative;
                @extend %py-4;
            }
            &-title {
                @extend %font-14-pm;
                @include truncate(2, 14);
            }
            &-thumbnail {
                width: 5.5rem;
                height: 5.5rem;
                @extend %px-3;
                @extend %py-2;
                img {
                    object-fit: cover;
                }
            }
            &-content {
                max-width: 18rem;
            }
            &-wrap {
                flex-wrap: wrap;
                @extend %flex;
            }
            &-meta {
                @include position(null, 0, 1.6rem, null, absolute);
            }
        }
        .subtotal {
            @include position(1.6rem, 0, null, null, absolute);
            @extend %text-center;
        }
        .product-count {
            height: 2.6rem;
            @include position(null, null, 1.6rem, 7rem, absolute);
            @extend %gap-3;
        }
        .subtotal,
        .btn-remove {
            width: 7rem;
        }
    }
    .btn-loading {
        pointer-events: none;
        @extend %relative;
        &::before {
            content: "";
            width: 2rem;
            height: 2rem;
            margin: auto;
            border: .4rem solid var(--neutral-50);
            border-top-color: var(--secondary-900);
            animation: button-loading-spinner 1s ease infinite;
            @extend %circle-radius;
            @extend %pos-center;
        }
        .btn-text {
            visibility: hidden;
        }
    }
}
@media (min-width: $tablet-min-width) {
    .waf-wp-shop-checkout {
        .accordion-body {
            .radio-box-wrap {
                width: 100%;
            }
        }
        .form-group {
            &-save-card,
            &.card-number {
                width: 100%;
            }
        }
        .form-footer {
            .btn-place-order {
                margin-top: var(--space-6);
            }
        }
        .globalmsg {
            padding-block: var(--space-6);
        }
    }
}
@media (min-width: $desktop-min-width) {
    .waf-wp-shop-checkout {
        .waf-body {
            gap: var(--space-4);
            @include flex-config(flex);
        }
        .form {
            &-section {
                width: calc(60% - var(--space-4));
            }
        }
        .cart-section {
            width: 40%;
            height: max-content;
        }
    }
}